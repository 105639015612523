/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Import necessary plugin functions
import vuetify from './vuetify'; // Vuetify plugin
import router from '@/router'; // Vue Router plugin
import store from '@/store'; // Vuex Store plugin
import { loadFonts } from './webfontloader'; // Function to load fonts
import { VueReCaptcha } from 'vue-recaptcha-v3';

// Define a function to register plugins
export function registerPlugins(app) {

  // Use Vuetify as a plugin
  app.use(vuetify);

  // Use Vue Router as a plugin
  app.use(router);

  // Use Vuex Store as a plugin
  app.use(store);

  // Load fonts using the imported function
  app.use(loadFonts);

  app.use(VueReCaptcha, { siteKey: '6LcD2aUnAAAAACJ5l43Mlx0XL8_WSJgp8b9aqhcz' })
}