// /store/index.js

import { createStore } from 'vuex'
import { Auth } from 'aws-amplify';

// Create a new store instance.
const store = createStore({
  state () {
    return {      
      drawer: false,
      links: [
        'Accueil',
        'Portfolio',
        'Techniques',
        'Masterclass',
        'Projets',
        'Shop',
        'Presse',
        'A propos',
        'Contact',
      ],
      thirdPartyCookiesAuthorized: localStorage.getItem('thirdPartyCookiesAuthorized') === 'true',
      isAuthenticated: false,
      cognitoCredentials: null,
    }
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setThirdPartyCookiesAuthorization(state, authorized) {
      state.thirdPartyCookiesAuthorized = authorized;
      localStorage.setItem('thirdPartyCookiesAuthorized', authorized);
    },
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setCognitoCredentials(state, credentials) {
      state.cognitoCredentials = credentials;
    },
  },
  actions: {
    async authenticate({ commit }, { email, password }) {
      try {
        await Auth.signIn(email, password);
        const currentSession = await Auth.currentSession();
        const cognitoCredentials = currentSession.getAccessToken().getJwtToken();

        commit('setAuthentication', true);
        commit('setCognitoCredentials', cognitoCredentials);
      } catch (error) {
        console.error('Authentication error:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        await Auth.signOut();
        commit('setAuthentication', false);
        commit('setCognitoCredentials', null);
      } catch (error) {
        console.error('Logout error:', error);
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    cognitoCredentials: state => state.cognitoCredentials,
  },
});

export default store;