// Composables
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/:pathMatch(.*)*',
    name: 'NotFound',
    components: {
      default: () => import('@/components/base/NotFound.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    components: {
      default: () => import('@/views/admin/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/',
    name: 'Accueil',
    components: {
      default: () => import('@/views/home/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    components: {
      default: () => import('@/views/portfolio/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/icones',
    name: 'Icones',
    components: {
      default: () => import('@/views/icones/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/batiks',
    name: 'Batiks',
    components: {
      default: () => import('@/views/batiks/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/aquarelles',
    name: 'Aquarelles',
    components: {
      default: () => import('@/views/aquarelles/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/portraits',
    name: 'Portraits',
    components: {
      default: () => import('@/views/portraits/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/nature',
    name: 'Nature',
    components: {
      default: () => import('@/views/nature/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/dessins',
    name: 'Dessins',
    components: {
      default: () => import('@/views/dessins/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/techniques',
    name: 'Techniques',
    components: {
      default: () => import('@/views/techniques/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/masterclass',
    name: 'Masterclass',
    components: {
      default: () => import('@/views/masterclass/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/projets',
    name: 'Projets',
    components: {
      default: () => import('@/views/projets/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/shop',
    name: 'Shop',
    components: {
      default: () => import('@/views/shop/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/presse',
    name: 'Presse',
    components: {
      default: () => import('@/views/presse/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/a-propos',
    name: 'A propos',
    components: {
      default: () => import('@/views/about/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: {
      default: () => import('@/views/contact/Index.vue'),
      NavBar: () => import('@/components/base/AppBar.vue'),
      Footer: () => import('@/components/base/Footer.vue'),
    }
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  // mode: 'history',
  routes,
})

export default router
