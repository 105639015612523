/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App
 */

// Components
import App from './App.vue'; // Root component of the application

// Composables
import { createApp } from 'vue'; // Vue composition API function

// Plugins
import { registerPlugins } from '@/plugins'; // Custom plugins registration
import 'vuetify/styles'; // Import Vuetify styles
import '@/saas/main.scss'; // Import custom SCSS styles

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'; // Amplify configuration file
Amplify.configure(awsExports);

const isLocalhost = window.location.hostname === 'localhost';

Amplify.configure({
    Auth: {
      cookieStorage: {
        domain: isLocalhost ? 'localhost' : 'astorgolga-art.com', // 'yourdomain.com', // Replace with your domain
        path: '/',
        expires: 7, // Number of days the cookie will last
        secure: !isLocalhost, // true, // Set this to true if using HTTPS
        sameSite: 'lax', // Or 'strict' based on your needs
        httpOnly: true,
      },
      // ... Other Auth configuration options
    },
  });

// Create a Vue app instance
const app = createApp(App);

// Register plugins using the defined function
registerPlugins(app);

// Mount the app to the DOM
app.mount('#app');