<template>
    <v-dialog v-model="dialogVisible" max-width="400px">
      <v-card>
        <v-card-title class="text-h5 text-center">
          Authorization Required
        </v-card-title>
        <v-card-text>
          We use third-party cookies to provide you with a better experience. Do you authorize their use?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="authorizeCookies">Authorize</v-btn>
          <v-btn color="error" @click="rejectCookies">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  
  const store = useStore();
  const dialogVisible = ref(!store.state.thirdPartyCookiesAuthorized);
  
  const authorizeCookies = () => {
    store.commit('setThirdPartyCookiesAuthorization', true);
    dialogVisible.value = false;
  };
  
  const rejectCookies = () => {
    store.commit('setThirdPartyCookiesAuthorization', false);
    dialogVisible.value = true;
  };
  
  const shouldShowDialog = computed(() => !store.state.thirdPartyCookiesAuthorized);
  </script>