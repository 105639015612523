<template>
  <v-app>
    <router-view  name="NavBar"></router-view>
    <cookie-authorization-dialog v-if="showCookieDialog" />

    <router-view />
    <router-view  name="Footer"></router-view>
  </v-app>
</template>

<script setup>
import CookieAuthorizationDialog from '@/components/base/CookieAuthorizationDialog';
import { useStore } from 'vuex';

const store = useStore();
const showCookieDialog = store.state.thirdPartyCookiesAuthorized === false;
</script>